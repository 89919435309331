export interface ErrorTSO {
    attributes?: any;
    error_code: number;
    message?: string;
}

export enum CipherAlgorithm {
    DES = 5,
    TRIPLE_DES = 15,
    BLOWFISH_256 = 20,
    AES = 25,
}

export const mapOfCipherAlgorithm: Map<CipherAlgorithm, EnumTSO> = new Map<
    CipherAlgorithm,
    EnumTSO
>([
    [CipherAlgorithm.DES, createEnum(0, 'DES', 5, 'DES')],
    [CipherAlgorithm.TRIPLE_DES, createEnum(1, 'TRIPLE_DES', 15, 'Triple DES')],
    [CipherAlgorithm.BLOWFISH_256, createEnum(2, 'BLOWFISH_256', 20, 'Blowfish')],
    [CipherAlgorithm.AES, createEnum(3, 'AES', 25, 'AES')],
]);

export enum DeviceOS {
    Linux = 10,
    MacOSX = 20,
    Solaris = 30,
    Unix = 40,
    Windows = 50,
}

export const mapOfDeviceOS: Map<DeviceOS, EnumTSO> = new Map<DeviceOS, EnumTSO>([
    [DeviceOS.Linux, createEnum(0, 'Linux', 10, 'Linux')],
    [DeviceOS.MacOSX, createEnum(1, 'MacOSX', 20, 'Mac OS X')],
    [DeviceOS.Solaris, createEnum(2, 'Solaris', 30, ' Solaris')],
    [DeviceOS.Unix, createEnum(3, 'Unix', 40, 'Unix')],
    [DeviceOS.Windows, createEnum(4, 'Windows', 50, 'Windows')],
]);

export enum HashAlgorithm {
    CRC32 = 2,
    MD5 = 5,
    SHA1 = 15,
    SHA256 = 20,
    SHA512 = 30,
    GoodFast32 = 35,
    GoodFast128 = 40,
}

export const mapOfHashAlgorithm: Map<HashAlgorithm, EnumTSO> = new Map<
    HashAlgorithm,
    EnumTSO
>([
    [HashAlgorithm.CRC32, createEnum(0, 'CRC32', 2, 'CRC32')],
    [HashAlgorithm.MD5, createEnum(1, 'MD5', 5, 'MD5')],
    [HashAlgorithm.SHA1, createEnum(2, 'SHA1', 15, 'SHA-1')],
    [HashAlgorithm.SHA256, createEnum(3, 'SHA256', 20, 'SHA-256')],
    [HashAlgorithm.SHA512, createEnum(4, 'SHA512', 30, 'SHA-512')],
    [HashAlgorithm.GoodFast32, createEnum(5, 'GoodFast32', 35, 'Good Fast-32')],
    [HashAlgorithm.GoodFast128, createEnum(6, 'GoodFast128', 40, 'Good Fast-128')],
]);

export enum ImageFormat {
    PNG = 1,
    JPEG = 2,
    GIF = 3,
    BMP = 4,
}

export const mapOfImageFormat: Map<ImageFormat, EnumTSO> = new Map<ImageFormat, EnumTSO>([
    [ImageFormat.PNG, createEnum(0, 'PNG', 1, 'PNG (Portable Network Graphics)')],
    [
        ImageFormat.JPEG,
        createEnum(1, 'JPEG', 2, 'JPEG (Joint Photographic Experts Group)'),
    ],
    [ImageFormat.GIF, createEnum(2, 'GIF', 3, 'GIF (Graphics Interchange Format)')],
    [ImageFormat.BMP, createEnum(3, 'BMP', 4, 'BMP (Bitmap Image)')],
]);

export enum StringFilterKind {
    Exact = 1,
    Contains = 2,
    StartsWith = 3,
    EndsWith = 4,
}

export const mapOfStringFilterKind: Map<StringFilterKind, EnumTSO> = new Map<
    StringFilterKind,
    EnumTSO
>([
    [StringFilterKind.Exact, createEnum(0, 'Exact', 1, 'Exact')],
    [StringFilterKind.Contains, createEnum(1, 'Contains', 2, 'Contains')],
    [StringFilterKind.StartsWith, createEnum(2, 'StartsWith', 3, 'Starts With')],
    [StringFilterKind.EndsWith, createEnum(3, 'EndsWith', 4, 'Ends With')],
]);

export interface CodeTSO extends TitleTSO {
    code?: string;
}

export function createCodeTSO(): CodeTSO {
    return {
        code: null,
        title: null,
    };
}

export interface EmailSettingsTSO {
    additional_bcc_recipients?: string[];
    additional_cc_recipients?: string[];
    additional_to_recipients?: string[];
    api_key?: string;
    clear_bcc_recipients: boolean;
    clear_cc_recipients: boolean;
    clear_to_recipients: boolean;
    default_from_address?: string;
    default_from_name?: string;
    default_reply_to_address?: string;
    default_reply_to_name?: string;
    default_subject?: string;
    enabled: boolean;
    host?: string;
    override_bcc_recipients?: string[];
    override_cc_recipients?: string[];
    override_to_recipients?: string[];
    password?: string;
    port?: number;
    ssl: boolean;
    username?: string;
}

export function createEmailSettingsTSO(): EmailSettingsTSO {
    return {
        additional_bcc_recipients: null,
        additional_cc_recipients: null,
        additional_to_recipients: null,
        api_key: null,
        clear_bcc_recipients: false,
        clear_cc_recipients: false,
        clear_to_recipients: false,
        default_from_address: null,
        default_from_name: null,
        default_reply_to_address: null,
        default_reply_to_name: null,
        default_subject: null,
        enabled: false,
        host: null,
        override_bcc_recipients: null,
        override_cc_recipients: null,
        override_to_recipients: null,
        password: null,
        port: null,
        ssl: false,
        username: null,
    };
}

export interface EnumTSO extends IntegerIdAndTitleTSO {
    name?: string;
    ordinal: number;
}

export function createEnumTSO(): EnumTSO {
    return {
        id: null,
        name: null,
        ordinal: 0,
        title: null,
    };
}

export function createEnum(
    ordinalValue: number,
    nameValue: string,
    idValue?: number,
    titleValue?: string,
): EnumTSO {
    return {
        id: idValue,
        title: titleValue,
        ordinal: ordinalValue,
        name: nameValue,
    };
}

export interface EnumListTSO {
    enum_class_name?: string;
    items?: EnumTSO[];
}

export function createEnumListTSO(): EnumListTSO {
    return {
        enum_class_name: null,
        items: null,
    };
}

export interface IntegerDeedTSO extends IntegerIdAndTitleTSO {
    on?: number;
}

export function createIntegerDeedTSO(): IntegerDeedTSO {
    return {
        id: null,
        on: null,
        title: null,
    };
}

export interface IntegerIdAndTitleAndEmailTSO extends IntegerIdAndTitleTSO {
    email?: string;
}

export function createIntegerIdAndTitleAndEmailTSO(): IntegerIdAndTitleAndEmailTSO {
    return {
        email: null,
        id: null,
        title: null,
    };
}

export interface IntegerIdAndTitleTSO extends IntegerIdTSO {
    title?: string;
}

export function createIntegerIdAndTitleTSO(): IntegerIdAndTitleTSO {
    return {
        id: null,
        title: null,
    };
}

export interface IntegerIdTSO {
    id?: number;
}

export function createIntegerIdTSO(): IntegerIdTSO {
    return {
        id: null,
    };
}

export interface LongDeedTSO extends LongIdAndTitleTSO {
    on?: number;
}

export function createLongDeedTSO(): LongDeedTSO {
    return {
        id: null,
        on: null,
        title: null,
    };
}

export interface LongIdAndTitleAndEmailTSO extends LongIdAndTitleTSO {
    email?: string;
}

export function createLongIdAndTitleAndEmailTSO(): LongIdAndTitleAndEmailTSO {
    return {
        email: null,
        id: null,
        title: null,
    };
}

export interface LongIdAndTitleTSO extends LongIdTSO {
    title?: string;
}

export function createLongIdAndTitleTSO(): LongIdAndTitleTSO {
    return {
        id: null,
        title: null,
    };
}

export interface LongIdTSO {
    id?: number;
}

export function createLongIdTSO(): LongIdTSO {
    return {
        id: null,
    };
}

export interface StringFilterTSO {
    kind?: StringFilterKind;
    value?: string;
}

export function createStringFilterTSO(): StringFilterTSO {
    return {
        kind: null,
        value: null,
    };
}

export interface StringIdAndTitleTSO extends StringIdTSO {
    title?: string;
}

export function createStringIdAndTitleTSO(): StringIdAndTitleTSO {
    return {
        id: null,
        title: null,
    };
}

export interface StringIdTSO {
    id?: string;
}

export function createStringIdTSO(): StringIdTSO {
    return {
        id: null,
    };
}

export interface TitleTSO {
    title?: string;
}

export function createTitleTSO(): TitleTSO {
    return {
        title: null,
    };
}

export interface VersionTSO {
    incremental: number;
    major: number;
    minor: number;
    qualifier?: string;
}

export function createVersionTSO(): VersionTSO {
    return {
        incremental: 0,
        major: 0,
        minor: 0,
        qualifier: null,
    };
}

export interface VersionFilterTSO {
    incremental?: number;
    major?: number;
    minor?: number;
    qualifier?: string;
}

export function createVersionFilterTSO(): VersionFilterTSO {
    return {
        incremental: null,
        major: null,
        minor: null,
        qualifier: null,
    };
}

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export function strToNgbDateStruct(dateStr: string): NgbDateStruct {
    dateStr = dateStr?.trim() || '';
    return dateStr.length === 8
        ? {
              year: +dateStr.substr(0, 4),
              month: +dateStr.substr(4, 2),
              day: +dateStr.substr(6, 2),
          }
        : null;
}

export function strToJsDate(dateStr: string): Date {
    dateStr = dateStr?.trim() || '';
    if (dateStr.length === 8) {
        const year = Number(dateStr.substr(0, 4));
        const month = Number(dateStr.substr(4, 2)) - 1;
        const day = Number(dateStr.substr(6, 2));
        return new Date(year, month, day, 0, 0, 0, 0);
    }

    return null;
}

export function strToLocalDateStr(dateStr?: string): string {
    const d = strToJsDate(dateStr);
    return d == null ? '' : d.toLocaleDateString();
}

export function ngbDateStructToStr(dateStruct?: NgbDateStruct): string {
    if (dateStruct == null) {
        return null;
    }

    if (dateStruct.year === 0 && dateStruct.month === 0 && dateStruct.day === 0) {
        return null;
    }

    return (
        String(dateStruct.year).padStart(4, '0') +
        String(dateStruct.month).padStart(2, '0') +
        String(dateStruct.day).padStart(2, '0')
    );
}

export function jsDateToNgbDateStruct(date?: Date): NgbDateStruct {
    if (date == null) {
        return null;
    }

    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
    };
}

export function epochToDateTime(epoch?: number): Date {
    if (epoch === null) {
        return null;
    }

    return new Date(epoch);
}

export function epochToDateTimeStr(epoch?: number): string {
    const d = epochToDateTime(epoch);
    return d == null ? '' : d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
}

export function nullToNgbDateStructToday(dateStruct?: NgbDateStruct): NgbDateStruct {
    return dateStruct === null ? jsDateToNgbDateStruct(new Date()) : dateStruct;
}

export function ngbDateStructToJsDate(dateStruct?: NgbDateStruct): Date {
    if (dateStruct == null) {
        return null;
    }

    return new Date(dateStruct.year, dateStruct.month, dateStruct.day, 0, 0, 0, 0);
}
